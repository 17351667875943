<template>
  <GmapMap
      :center='center'
      :zoom='17'
      style='width:100%;  height: 400px;'
>
  <GmapMarker
    :position="this.center"
    :clickable="true"
  />
</GmapMap>
</template>

<script>
export default {
  name: 'GoogleMap',
  props: ['center'],
  data() {
    return {
    }
  },
};
</script>
