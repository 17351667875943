import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { initializeApp } from "firebase/app";

const firebaseConfig = {

    apiKey: "AIzaSyDaA-fVpHlgFbu1Zv-0GZx_miyht_QB7o0",
  
    authDomain: "rimi-sos.firebaseapp.com",
  
    projectId: "rimi-sos",
  
    storageBucket: "rimi-sos.appspot.com",
  
    messagingSenderId: "759802660186",
  
    appId: "1:759802660186:web:1d69ccb03895bf2a331f63",
  
    measurementId: "G-TPC6FPGK6C"
  
  };
    // Initialize Firebase
    const firebaseApp = initializeApp(firebaseConfig);
    const db = getFirestore(firebaseApp);

    export default db