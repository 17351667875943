<template>
<div ref="app">
<v-app>
      <nav>  
    <!-- Appbar -->
    <v-app-bar app dark dense height="75" class="app_bar">
      <div id="rimi_logo" ref="wholeApp">
          <v-toolbar-title>
              <v-layout>
                <v-flex fill-height class="d-flex align-center justify-center">
                  <img height="60" src="./assets/rimi2.png" class="mr-4">
                    <h3 class="center red--text text--darken-1 mr-3">
                      SOS 
                    </h3>
                    <v-row align="center" justify="center">
                      <v-col cols="12" class="d-flex align-center justify-center">
                        <h5>
                      Valós idejű ügyfél pozíció
                    </h5>
                      </v-col>
                    </v-row>
                    
                </v-flex>
              </v-layout>
          </v-toolbar-title>
      </div>
    </v-app-bar>
      </nav>
  <div id="app">
    <GoogleMap 
      :zoom='12'
      :style='style'
      :center=center
    />
  </div>
</v-app>
</div>
</template>

<script>
import GoogleMap from './components/GoogleMap.vue'
import db from '@/firebase/init'
import { doc, onSnapshot, collection, query, where, getDoc, getDocs, setDoc } from "firebase/firestore";

export default {
  name: 'App',
  data() {
    return {
      data: null,
      id: this.$route.query.id,
      center: {'lat':37, 'lng':-122},
      itemHeight: 600,
      style: 'width:100%;  height: 943px;',
    }
  },
  components: {
    GoogleMap,
  },
  async created(){

//     const querySnapshot = await getDocs(collection(db, "gps"));
//       querySnapshot.forEach((doc) => {
//         if(doc.id == this.id){
//           this.center.lat = parseFloat(doc.data().latitude)
//           this.center.lng = parseFloat(doc.data().longitude)
//       }


// });

      const unsub = onSnapshot(
      doc(db, 'gps', this.id),
      (snapshot) => {
        this.center.lat = parseFloat(snapshot.data().latitude)
        this.center.lng = parseFloat(snapshot.data().longitude)
      }
    )

  },
  mounted(){
    this.itemHeight = this.$refs.app.scrollHeight
    this.style = 'width:100%;  height: ' + this.itemHeight.toString()+ 'px;'

  },
};
</script>
